import React from "react";
import styled from "styled-components";
import Button from "./Button";


const Support = ({ inline = undefined, theme = undefined }) => (
  <StyledSupport>
    {/*<span className="helper-fill-gatsby-image">
      <StaticImage src="../images/NYT_OO_ Thailand_HighRes_71.jpg" alt="" />
    </span>*/}
      <h2>Reporting of this type is<br /> rare, expensive, and urgently needed.</h2>
      <p>The Outlaw Ocean Project is a non-profit journalism organization solely dedicated to producing these stories, which we distribute globally for free. Please consider supporting the work with a financial contribution.</p>
      <div>
        <Button className="is-dark" as="a" href="https://donate.stripe.com/fZe6rS0Qxc6M25y9AA" target="_blank" rel="noopener noreferrer">Donate</Button>
        <Button className="is-dark" as="a" href="https://theoutlawocean.substack.com/subscribe" target="_blank" rel="noopener noreferrer">Subscribe</Button>
      </div>
      {/*<small>Or <a href="https://theoutlawocean.substack.com/subscribe" target="_blank" rel="noopener noreferrer">subscribe</a> to our newsletter.</small>*/}
  </StyledSupport>
);
export default Support;


const StyledSupport = styled.aside`
  position: relative;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
  z-index: 2;
  transform: translate3d(0,0,0);
  @media ( min-width: 48em ) {
    padding: 6em 0;
  }
  background-color: var(--color-white);
  color: var(--color-black);
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
  }
  span {
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    z-index: -1;
    border-top: 0.5px solid var(--color-white);
    .gatsby-image-wrapper {
      opacity: 0.4;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 1.5em;
    @media ( min-width: 48em ) {
      font-size: 2.25em;
    }
    line-height: 1.4;
    margin: 0;
    em {
      font-style: normal;
      display: block;
    }
  }
  p {
    position: relative;
    //font-family: var(--font-serif);
    font-size: 1em;
    font-weight: var(--font-sans-weight);
    margin: 1em auto 1.75em;
    //max-width: 32em;
    max-width: 36em;
    @media ( min-width: 48em ) {
      font-size: 1.25em;
    }
  }
  > div {
    display: grid;
    gap: 1.5em;
    grid-template: auto / 1fr 1fr;
  }
`;